import styled from '@emotion/styled';
import _ from 'lodash';
import dynamic from 'next/dynamic';

import { PSection } from 'src/interface/section';

import { useRouter } from 'next/router';
import CardFull from 'src/components/cards/card-full';
import CardH from 'src/components/cards/card-h';
import Carousels from 'src/components/carousels';
import SectionHeader from 'src/components/sections/section-header';
import WidgetBackline from 'src/components/widget/widget-backlink';
import { SHOW_COMMEMORATE } from 'src/constants';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { Media } from 'src/interface/media';
import SectionEvent1 from 'src/section-events/events-014';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import useResponsive from 'src/utils/devices';
import CardHalf from '../../cards/card-half';
// import SectionOneCard from '../section-card/section-one';

const SectionThree = dynamic(import('src/components/sections/section-home/section-three'));
type Props = {
  block1: PSection['block1'];
  block2: PSection['block2'];
  block3: PSection['block3'];
  eventDataLayer?: IDataLayerGlobal;
  soft?: any;
};

const SectionTwo = ({ block1, block2, block3, eventDataLayer, soft }: Props) => {
  const router = useRouter();
  const responsive: Media = useResponsive();

  const detectfront = responsive.isLaptop ? 4 : 1;
  const detectend = responsive.isLaptop ? 8 : 5;

  return (
    <>
      <SectionWrapper id='section-3' className='wrapper-1' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <SectionEvent1 dataSoft={soft} />
        {!_.isEmpty(block1) && (
          <div className='container'>
            <SectionHeader data={block1?.title} link={block1?.link} fontColor={'#000'} />
            <div className='block-wrapper'>
              <div className='block-1'>
                {responsive.isMobile && (
                  <>
                    {_.map(block1?.data?.slice(0, 1), (element, index) => (
                      <CardHalf
                        key={index}
                        data={element}
                        showFooter={false}
                        showBlurb={false}
                        showBadge={false}
                        color={'#fff'}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          position: 'ใต้:section-2',
                          section: 'section-3',
                          data: {
                            block: '1',
                            heading: block1?.title,
                            title: element?.title,
                            carousel: index + 1
                          }
                        }}
                      />
                    ))}

                  </>
                )}
                {responsive.isLaptop && (
                  <Carousels dots={true} mobileSlideShow={1} slideShow={1} showArrow={true}>
                    {_.map(block1?.data?.slice(0, 5), (element, index) => (
                      <CardFull
                        key={index}
                        data={element}
                        showFooter={false}
                        showBlurb={false}
                        showBadge={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          position: 'ใต้:section-3',
                          section: 'section-4',
                          data: {
                            block: '1',
                            heading: block1?.title,
                            title: element?.title,
                            carousel: index + 1
                          }
                        }}
                      />
                    ))}
                  </Carousels>
                )}
              </div>
              <div className='block-2'>
                {_.map(block1?.data?.slice(detectfront, detectend), (element, index) => (
                  <CardH
                    key={index}
                    data={element}
                    showFooter={true}
                    showBlurb={true}
                    showBadge={false}
                    // ! DATALAYER
                    eventDataLayer={{
                      ...eventDataLayer,
                      position: 'ใต้:section-3',
                      section: 'section-4',
                      data: {
                        block: '2',
                        heading: block1?.title,
                        title: element?.title,
                        carousel: index + 5
                      }
                    }}
                  />
                ))}
              </div>
            </div>

            <WidgetBackline link={block1?.link} />
          </div>
        )}
      </SectionWrapper>

      <SectionThree
        block1={block2}
        block2={block3}
        eventDataLayer={{
          position: 'ใต้:section-3',
          section: 'section-4',
          type: DATALAYER_TYPE.TRACK_POSITION,
          router: router?.pathname

        }}

      />
      {/* <SectionWrapper id='section-6' className='wrapper-2' >
        <SectionOneCard
          settrade={true}
          data={block2}
          // ! DATALAYER
          eventDataLayer={{
            ...eventDataLayer,
            position: 'ใต้:section-5',
            section: 'section-6',
            data: {
              block: '1',
              heading: block2?.title
            }
          }}
        />
      </SectionWrapper> */}

    </>
  );
};

export default SectionTwo;

const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  padding: 30px 0;
  .heading-click-on-section {
    display: none;
    @media (max-width: 690px) {
      display: block;
    }
  }
  .heading-click {
    @media (max-width: 690px) {
      display: none;
    }
  }
  &.wrapper-1 {
    .block-wrapper {
      display: flex;
      gap: 10px;

      @media (max-width: 690px) {
        margin-bottom: 10px;
      }
      .blurb {
        display: none;
      }
      @media (max-width: 960px) {
        flex-direction: column;
        .blurb {
          display: block;
        }
      }
      @media (max-width: 690px) {
        .blurb {
          display: none;
        }
      }
      .block-1 {
        margin-top: 10px;
        width: 65%;
        .title {
          font-size: 34px; 
          @media (max-width: 690px) {
            margin-top: 0px;
            font-size: 18px; 
          }
        }
        @media (max-width: 960px) {
          width: 100%;
        }
      }
      .block-2 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        width: 45%;
        @media (max-width: 960px) {
          width: 100%;
        }
      }
    }
  }
  &.wrapper-3 {
    .block-wrapper {
      margin-bottom: 10px;
      .block-1 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        margin-top: 10px;
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      .block-2 {
        @media (max-width: 768px) {
          margin-top: 10px;
          display: grid;
          grid-gap: 10px;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
`;

