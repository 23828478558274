import { IoChevronForwardCircle } from 'react-icons/io5';
import { splitRote } from 'src/utils/helper';

import { useRouter } from 'next/router';
import styled from '@emotion/styled';

type Props = {
  link: any;
};
const WidgetBackline = ({ link }: Props) => {
  const router = useRouter();
  return (
    <WidgetWrapper className='heading-click-on-section'>
      <li>
        <a href={`${splitRote(router)}${link || '#'}`}>
          <p>
            ดูทั้งหมด <IoChevronForwardCircle size={20} />
          </p>
        </a>
      </li>
    </WidgetWrapper>
  );
};

export default WidgetBackline;

const WidgetWrapper = styled.ul`
  &.heading-click-on-section {
    display: none;
    @media (max-width: 690px) {
      display: block;
      li {
        cursor: pointer;
        p {
          color: #545454;
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          svg {
            margin: 0px 0 0 8px;
          }
        }
        .current {
          color: var(--text-color);
          background: var(--primary-gradient);
          background-blend-mode: overlay, normal;
          border-radius: 15px;
          padding: 2px 14px;
        }
      }
    }
  }
`;
