import styled from '@emotion/styled';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { GET } from 'src/services';

import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { convertDatasets } from 'src/utils/datasets';
import { convertObjPath } from 'src/utils/helper';

import { useRouter } from 'next/router';
import useResponsive from 'src/utils/devices';
import { Media } from 'src/interface/media';
import Carousels from './carousels';
import CardFull from 'src/components/cards/card-full';

const SectionEvent1 = ({ dataSoft }: any) => {

  if (_.isEmpty(dataSoft)) return null;
  const router = useRouter();

  let linkMore: any = '';
  let tag: any = [];
  let keysTag: any = '';
  let link: any = '';

  const softData = {
    item1: convertDatasets(dataSoft?.[0], 'image-left-sce3', true),
    item2: convertDatasets(dataSoft?.[0], 'image-bg-sce3', true),
    item3: convertDatasets(dataSoft?.[0], 'image-right-sce3', true),
    item4: convertDatasets(dataSoft?.[0], 'title-sce3', true),
    item5: convertDatasets(dataSoft?.[0], 'data-sce3', true),
    item6: convertDatasets(dataSoft?.[0], 'image-bg-sce3-mb', true),
    item7: convertDatasets(dataSoft?.[0], 'open-sce3', true),
    item9: convertDatasets(dataSoft?.[0], 'switch-key-sce3', true),
    item10: convertDatasets(dataSoft?.[0], 'image-logo-sce3', true),
    item11: convertDatasets(dataSoft?.[0], 'image-other-sce3', true),
    item12: convertDatasets(dataSoft?.[0], 'image-cloud-sce3', true)
  };

  if (softData.item9 === 'tag') {
    if (softData.item5.indexOf('/') !== -1) {
      tag = softData.item5.split('/');
      keysTag = tag[1];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      keysTag = softData.item5;
    }

    linkMore = `/api/search/tag${convertObjPath({
      name: keysTag
    })}&limit=3`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    link = `/tags/${keysTag}`;
  } else if (softData.item9 === 'category') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    linkMore = `/api/v1.0/categories${softData.item5}`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    link = `/category${softData.item5}`;
  }

  const [resI, setResI]: any = useState();
  const responsive: Media = useResponsive();

  useEffect(() => {
    const fetchData = async () => {
      if (!_.isEmpty(softData?.item5) && softData.item7 === 'true') {
        const resI = await GET(
          `${linkMore}`,
          false,
          false
        );
        setResI(resI);

      }
    };

    fetchData()
      .catch(console.error);
  }, []);

  const resData = !_.isEmpty(resI) ? resI.slice(0, 3) : [];

  return (
    <>
      {!_.isEmpty(resData) && (
        <SectionWrapper backgroundImage={softData?.item2} backgroundImageMb={softData?.item6}>
          <div>
            <div className='container detail-sec-1'>
              {!_.isEmpty(softData?.item10) && <img className='image-left-sce1' src={softData?.item10} alt={softData?.item4} title={softData?.item4} width={'100%'} height={'100%'} />}
              {!_.isEmpty(softData?.item12) && <img className='image-left-bottom-sce1' src={softData?.item12} alt={softData?.item4} title={softData?.item4} width={'100%'} height={'100%'} />}
              {!_.isEmpty(softData?.item11) && <img className='image-right-sce1' src={softData?.item11} alt={softData?.item4} title={softData?.item4} width={'100%'} height={'100%'} />}
              {!_.isEmpty(softData?.item4) && <img className='image-title-head' src={softData?.item4} alt={softData?.item4} title={softData?.item4} width={'100%'} height={'100%'} />}
              {/* <h3 className='title-head'>{softData?.item4}</h3> */}
              <p className='read-more'>
                <a href={`${link}`}>ดูทั้งหมด</a>
              </p>
              {responsive.isMobile && (
                <Carousels style='close-btn' dots={true} mobileSlideShow={1} slideShow={1} showArrow={true} autoplaySpeed={7000}>
                  {_.map(resData, (item, i) => (
                    <div className='card-item' key={i}>
                      <CardFull
                        data={item}
                        showFooter={false}
                        showBlurb={false}
                        showTitle={true}
                        showBadge={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router.pathname,
                          section: 'section-4',
                          position: 'ใต้:section-3',
                          data: {
                            block: '1',
                            heading: softData?.item4,
                            title: item?.title,
                            carousel: i + 1
                          }
                        }}
                      />
                    </div>
                  ))}
                </Carousels>
              )}
              {responsive.isLaptop && (
                <div className='card-list'>
                  {_.map(resData, (item, i) => (
                    <div className='card-item' key={i}>
                      <CardFull
                        data={item}
                        showFooter={false}
                        showBlurb={false}
                        showTitle={true}
                        showBadge={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router.pathname,
                          section: 'section-4',
                          position: 'ใต้:section-3',
                          data: {
                            block: '1',
                            heading: softData?.item4,
                            title: item?.title,
                            index: i + 1
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!_.isEmpty(softData?.item3) && <img className='image-right-sce1' src={softData?.item3} alt={softData?.item4} title={softData?.item4} width={'100%'} height={'100%'} />}

          </div>
        </SectionWrapper>
      )}
    </>
  );
};

export default SectionEvent1;

const SectionWrapper = styled.div<{
  backgroundImage: any;
  backgroundImageMb: any;
}>`
    background: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : ''} ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    margin-top: -30px;
    margin-bottom: 30px;
    padding-top: 30px;
    @media (max-width: 690px) {
      padding-top: 0;
      }
    @media (max-width: 425px) {
      background: ${props => props.backgroundImageMb ? `url(${props.backgroundImageMb})` : ''} ;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .image-right-sce1 {
      position: absolute;
      bottom: 30px;
      right: -120px;
      @media (max-width: 1024px) {
        right: -60px;
      }
      @media (max-width: 768px) {
        right: -30px;
      }
    }
    .image-left-sce1 {
      position: absolute;
      top: -10px;
      left: -100px;
      @media (max-width: 690px) {
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .image-left-bottom-sce1 {
      position: absolute;
      top: 130px;
      left: -200px;
      width: 100%;
      max-width: 200px;
      height: auto;
      @media (max-width: 690px) {
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
      }
    }
    .image-title-head {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 400px;
      height: auto;
      @media (max-width: 690px) {
        position: relative;
        /* top: 0; */
        /* left: 50%;
        transform: translateX(-50%); */
        max-width: 300px;
        display:block;
        margin-bottom: 70px;
      }
    }
    .image-left-sce1, .image-right-sce1 {
      z-index: 1;
      width: 100%;
      max-width: 85px;
      height: auto;
      @media (max-width: 1024px) {
        max-width: 90px;
      }
    }
    .detail-sec-1 {
      position: relative;
      text-align: center;
      padding: 30px 15px;
      font-weight: 300;
      font-size:46px;
      color: #fff;
      @media (max-width: 1024px) {
        font-size: 24px;
      }
      @media (max-width: 690px) {
        font-size: 20px;
        /* padding: 60px 15px 30px 15px; */
      }
      h3.title-head {
        line-height : 1;
        font-weight: 700;
        margin-bottom: 15px;
        color: #ED1C24;
        stroke: #fff;
        stroke-width: 1;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #fff;
        @media (max-width: 1024px) {
          -webkit-text-stroke-width: 1px;
        }
        @media (max-width: 690px) {
          margin-bottom: 70px;
          -webkit-text-stroke-width: unset;
        }
      }
      .read-more {
        position: relative;
        font-size: 14px;
        font-weight: 700;
        text-align: right;
        z-index: 1;
        background-color:  var(--primary-color);
        border: 1px solid var(--primary-color);
        max-width: max-content;
        margin: 0 0 0 auto;
        /* margin: auto; */
        padding: 2px 15px;
        border-radius: 3px;
        /* margin-bottom: 20px; */
          a {
            color: #fff;
          }
          &:hover{
            a {
              color: var(--primary-color);
            }
            background-color: #fff;
            border: 1px solid var(--primary-color);
          }
          @media (max-width: 690px) {
            margin-bottom: 15px;
          }
      }
    }

    .card-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      padding: 12px 0 0 0;
      position: relative;
      z-index: 1;
      @media (max-width: 1024px) {
        padding: 30px 0 30px 0;
      }
    }
    .card-item {
      position: relative;
      z-index: 1;
      margin: 0 -5px;
      .card-full {
        .card-image {
          /* box-shadow: 0px 2px 10px 1px #00000033; */
          border-radius:5px;
          .card-content {
            /* height: 30%; */
            text-align: left;
          }
        }
      }
    }
`;